import React from 'react';
import useMediaQuery from '@veneer/core/dist/scripts/utils/use_media_query';
import { WideCard } from '../WideCard';
import { YearlyPlansCard } from '../YearlyPlansCard';
import { useGetText } from '../../../common/hooks/useGetText';
import { Carousel } from '../Carousel';
import { PlanCardContainer, Title, BoldText } from './styles';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';
import { useJarvisPlansState } from '../../../common/hooks/useJarvisPlansState';
import { ARN_STEP } from '../../../common/constants';
import { ConfirmYearlyChange } from '../../components/ConfirmYearlyChange';

export const AllYearlyPlansSelector: React.FC = () => {
  const getText = useGetText();
  const { currentStep } = useJarvisPlansState();
  const isTablet = useMediaQuery(
    `(max-width: ${breakpointsHelper.medium_min_768px})`
  );
  const isMobile = useMediaQuery(
    `(max-width: ${breakpointsHelper.extra_small_575px})`
  );

  const PlanCards = () => {
    return (
      <PlanCardContainer>
        <YearlyPlansCard
          numberOfCircles={1}
          pagesPerYear="120"
          pricePerYear="$15.88"
          savedPricePerYear="$74.99"
        />
        <YearlyPlansCard
          numberOfCircles={2}
          pagesPerYear="600"
          pricePerYear="$83.88"
          savedPricePerYear="$74.99"
        />
        <YearlyPlansCard
          numberOfCircles={3}
          pagesPerYear="1200"
          pricePerYear="$167.88"
          savedPricePerYear="$150.99"
          hasTag={true}
        />
        <YearlyPlansCard
          numberOfCircles={4}
          pagesPerYear="3600"
          pricePerYear="$83.88"
          savedPricePerYear="$74.99"
        />
        <YearlyPlansCard
          numberOfCircles={5}
          pagesPerYear="8400"
          savedPricePerYear="$251.88"
          pricePerYear="$225.00"
        />
        <YearlyPlansCard
          numberOfCircles={6}
          pagesPerYear="8400"
          savedPricePerYear="$335.85"
          pricePerYear="$300.88"
        />
        <YearlyPlansCard
          numberOfCircles={7}
          pagesPerYear="18000"
          savedPricePerYear="$659.88"
          pricePerYear="$595.00"
        />
      </PlanCardContainer>
    );
  };

  if (currentStep === ARN_STEP) {
    return <ConfirmYearlyChange />;
  }

  return (
    <>
      <Title>
        {getText('plans_selector.yearly.modals.all_yearly_plans.title')}
      </Title>
      <WideCard pagesPerMonth="100" pricePerMonth="6.99" />
      {isTablet ? (
        <PlanCards />
      ) : (
        <Carousel>
          <PlanCards />
        </Carousel>
      )}
      <p>
        <BoldText $isMobile={isMobile}>
          {getText('plans_selector.yearly.modals.all_yearly_plans.print_more')}
          &nbsp;
        </BoldText>
        {getText('plans_selector.yearly.modals.all_yearly_plans.renew_plan')}
      </p>
    </>
  );
};
