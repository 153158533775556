import React, { useState } from 'react';
import Button from '@veneer/core/dist/scripts/button';
import {
  useDispatch,
  useGetText,
  usePlanSelectorV3State
} from '../../../../../hooks';

import { Container, PlanPriceContainer } from './styles';
import { Plan } from 'src/EnrollmentPlans/V3/types/planSelectorV3State';

export const PlanSelection: React.FC<{
  plan: Plan;
  isLastChild?: boolean;
}> = ({ plan, isLastChild }) => {
  const getText = useGetText();
  const dispatch = useDispatch();
  const { onPlanChange, isSavingPlan, trackClickEvent } =
    usePlanSelectorV3State();
  const [loading, setLoading] = useState(false);
  const isInkPaper = plan.program === 'i_ink_paper';

  const savePlanChange = async () => {
    if (dispatch) {
      try {
        setLoading(true);
        dispatch({ type: 'SAVE_PLAN' });
        await onPlanChange(plan.id, isInkPaper);
        dispatch({ type: 'SAVE_PLAN_SUCCESS' });
      } catch (error) {
        console.log(error);
        dispatch({ type: 'SAVE_PLAN_FAIL' });
      }
      setLoading(false);
    }
    trackClickEvent(
      `select-plan-ink-${isInkPaper ? 'and-paper' : 'only'}`,
      'ii_plan_selector'
    );
  };
  return (
    <Container isLastChild={isLastChild} data-testid="plan-selection">
      <PlanPriceContainer>
        <h1>{plan.price}</h1>
        <span>{getText('plans_selector.plan_card.per_month')}</span>
      </PlanPriceContainer>
      <Button
        data-testid={`select-plan-ink-${isInkPaper ? 'and-paper' : 'only'}`}
        disabled={isSavingPlan}
        loading={isSavingPlan && loading}
        onClick={() => savePlanChange()}
      >
        {getText('plans_selector.select_plan')}
      </Button>
    </Container>
  );
};
