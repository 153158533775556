import React from 'react';
import {
  PlanCard,
  ColorContainer,
  InfoContainer,
  RadioButtonGroup,
  TransparentTag,
  PlanTagContainer
} from './styles';
import { PlanName } from '../../PlanName';
import { PagesPriceInfo } from '../../PagesPriceInfo';
import { PlanTag } from '../../PlanTag';
import { useConsumerPlansState } from '../../../../hooks/useConsumerPlansState';
import { useDispatch } from '../../../../hooks/useDispatch';
import { ANALITYCS_EVENT_LABEL } from '../../../../../../common/constants';
import { Plan } from '../../../../types/consumerPlansState';
import RadioButton from '@veneer/core/dist/scripts/radio_button/radio_button';
import { useTheme } from '@veneer/theme';
import {
  updatePlanSelection,
  isSelectedPlan
} from '../../../../../../common/utils/planCardSelector';

interface DesktopPlanCardProps {
  index: number;
  plan: Plan;
  defaultPlanGUID?: string;
  tagName: string;
  isInkPaper?: boolean;
}

export const DesktopPlanCard: React.FC<DesktopPlanCardProps> = ({
  index,
  plan,
  defaultPlanGUID,
  tagName,
  isInkPaper = false
}) => {
  const { program, trackClickEvent, selectedPlan, isHPX } =
    useConsumerPlansState();
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectProgram = isInkPaper ? 'i_ink_paper' : program;

  return (
    <PlanCard
      data-testid={`plans-selector-desktop-plan-card-${index}`}
      data-linkid="ii_plans"
      data-analyticsid="ii_plans"
      program={selectProgram}
      isSelected={isSelectedPlan(plan, selectedPlan.guid, isInkPaper)}
      isHPX={isHPX}
      onClick={() => {
        updatePlanSelection(
          plan,
          index,
          isInkPaper,
          trackClickEvent,
          ANALITYCS_EVENT_LABEL,
          dispatch
        );
      }}
    >
      <PlanTagContainer>
        {plan.guid === defaultPlanGUID ? (
          <PlanTag
            color={isHPX ? theme.color.background.default : ''}
            dataTestId={'plans-selector-plan-card-' + index}
            tagTitle={tagName}
          />
        ) : (
          <TransparentTag />
        )}
      </PlanTagContainer>
      <InfoContainer>
        <PlanName
          dataTestId={'plans-selector-plan-card-' + index}
          planName={plan.frequency}
          circlesNumber={index + 1}
          isInkPaper={isInkPaper}
        />
        <PagesPriceInfo
          dataTestId={'plans-selector-plan-card-' + index}
          pages={plan.pages}
          price={plan.price}
        />
      </InfoContainer>

      <RadioButtonGroup name="card-radio-buttons" value={selectedPlan.guid}>
        <RadioButton
          data-testid={`plan-radio-button-${plan.program}-${plan.pages}`}
          data-linkid={ANALITYCS_EVENT_LABEL}
          aria-label={`${plan.pages}-page-plan`}
          id={isInkPaper ? `${plan.guid}_i_ink_paper` : plan.guid}
          value={isInkPaper ? `${plan.guid}_i_ink_paper` : plan.guid}
        />
      </RadioButtonGroup>
      <div style={{ pointerEvents: 'none' }}>
        <ColorContainer
          program={isInkPaper ? 'i_ink_paper' : program}
          isVisible={
            isSelectedPlan(plan, selectedPlan.guid, isInkPaper) && !isHPX
          }
        />
      </div>
    </PlanCard>
  );
};
