import React from 'react';
import useMediaQuery from '@veneer/core/dist/scripts/utils/use_media_query';
import { Stepper } from './components/Stepper';
import {
  Container,
  Title,
  Subtitle,
  DividingLine,
  HeaderImage
} from './styles';
import { JarvisARN } from './components/JarvisARN';
import { useGetText } from '../../../common/hooks/useGetText';
import { breakpointsHelper } from '../../../common/utils/breakpointsHelper';
import ARNImage from '../../../../imgs/yearly-plans-arn-modal.png';

export const ConfirmYearlyChange: React.FC = () => {
  const isTablet = useMediaQuery(
    `(max-width: ${breakpointsHelper.medium_min_768px})`
  );

  const getText = useGetText();
  const stepContent = [
    {
      stepTitle: getText('plans_selector.yearly_confirmation.modals.plan_end', {
        end_billing_cycle: '(DATE)'
      }),
      topics: [
        getText(
          'plans_selector.yearly_confirmation.modals.unused_monthly_plan'
        ),
        getText('plans_selector.yearly_confirmation.modals.unused_message'),
        getText('plans_selector.yearly_confirmation.modals.refunds')
      ]
    },
    {
      stepTitle: getText(
        'plans_selector.yearly_confirmation.modals.charge_information'
      )
    },
    {
      stepTitle: getText(
        'plans_selector.yearly_confirmation.modals.automatic_renew_information'
      )
    }
  ];
  return (
    <>
      {!isTablet && <HeaderImage src={ARNImage} alt="ARN banner" />}
      <Container>
        <Title>
          {getText('plans_selector.yearly_confirmation.modals.confirm_title')}
        </Title>
        <Subtitle>
          {getText('plans_selector.yearly_confirmation.modals.what_expect')}
        </Subtitle>
        {stepContent.map((step, index) => (
          <Stepper
            key={index}
            index={index}
            stepTitle={step.stepTitle}
            topics={step.topics}
            isLastTopic={index === stepContent.length - 1}
          />
        ))}
        <DividingLine />
        <JarvisARN />
      </Container>
    </>
  );
};
