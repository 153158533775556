import React from 'react';
import { CompareYearlyAndMonthlyPlan } from '../../components/CompareYearlyAndMonthlyPlan';
import {
  ARN_STEP,
  COMPARE_YEARLY_MONTHLY_STEP,
  YEARLY_PLAN_SELECTOR_STEP
} from '../../../common/constants';
import { ConfirmYearlyChange } from '../../components/ConfirmYearlyChange';
import { useJarvisPlansState } from '../../../common/hooks/useJarvisPlansState';
import { AllYearlyPlansSelector } from '../AllYearlyPlansSelector';

export const YearlyPlanSelectorContent: React.FC = () => {
  const { currentStep } = useJarvisPlansState();

  switch (currentStep) {
    case COMPARE_YEARLY_MONTHLY_STEP:
      return <CompareYearlyAndMonthlyPlan />;
    case ARN_STEP:
      return <ConfirmYearlyChange />;
    case YEARLY_PLAN_SELECTOR_STEP:
      return <AllYearlyPlansSelector />;
    default:
      return <CompareYearlyAndMonthlyPlan />;
  }
};
