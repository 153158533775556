import React, { useState } from 'react';
import IconPriceTag from '@veneer/core/dist/scripts/icons/icon_price_tag';
import Button from '@veneer/core/dist/scripts/button';
import primitives from '@veneer/primitives';
import {
  YearlyCardContainer,
  ButtonContainer,
  TitleContainer,
  Description,
  ImageContainer,
  YearlyImage,
  NewTag,
  IconPriceTagContainer,
  NewTagContent,
  YearlyModal
} from './styles';
import { useGetText } from '../../hooks/useGetText';
import { useDispatch } from '../../../common/hooks/useDispatch';
import { setCurrentStepAction } from '../../../common/actions/setCurrentStepAction';
import { YearlyPlanSelector } from '../../../YearlyPlanSelector';
import { useJarvisPlansState } from '../../../common/hooks/useJarvisPlansState';
import { useDashboardPlansState } from '../../hooks/useDashboardPlansState';

export const YearlyCard: React.FC = () => {
  const getText = useGetText();
  const jarvisDispatch = useDispatch();
  const { currentStep, initialStep } = useJarvisPlansState();
  const { country, language, plans, onARNConfirmClick } =
    useDashboardPlansState();

  const [showCompareYearlyPlans, setShowCompareYearlyPlans] = useState(false);

  const handleYearlyModalClose = () => {
    setShowCompareYearlyPlans(false);

    if (jarvisDispatch) {
      jarvisDispatch(setCurrentStepAction(initialStep));
    }
  };

  return (
    <>
      <YearlyCardContainer
        data-testid="dashboard-plan-selector-yearly-card-container"
        onClick={() => setShowCompareYearlyPlans(true)}
      >
        <ImageContainer>
          <YearlyImage />
        </ImageContainer>
        <NewTag>
          <NewTagContent>
            <IconPriceTagContainer>
              <IconPriceTag color={primitives.color.white} size={16} />
            </IconPriceTagContainer>
            <p>{getText('plans_selector.yearly_card.new_tag')}</p>
          </NewTagContent>
        </NewTag>
        <TitleContainer>
          <h1>{getText('plans_selector.yearly_card.freedom_print')}</h1>
        </TitleContainer>
        <Description
          dangerouslySetInnerHTML={{
            __html: getText('plans_selector.yearly_card.card_content')
          }}
        />
        <ButtonContainer>
          <Button
            data-testid="yearly-plans-compare"
            data-linkid="ii_plans"
            data-analyticsid="ii_plans"
          >
            {getText('plans_selector.yearly_card.compare_yearly')}
          </Button>
        </ButtonContainer>
      </YearlyCardContainer>
      <YearlyModal
        $currentStep={currentStep}
        show={showCompareYearlyPlans}
        closeButton
        onClose={handleYearlyModalClose}
      >
        <YearlyPlanSelector
          plans={plans}
          country={country}
          language={language}
          currentPlanGUID=""
          onARNConfirmClick={onARNConfirmClick}
          onCloseModal={handleYearlyModalClose}
        />
      </YearlyModal>
    </>
  );
};
