import React, { useState } from 'react';
import {
  Container,
  Title,
  Description,
  LearnMore,
  ItemsContainer,
  TitleContainer
} from './styles';
import Modal from '@veneer/core/dist/scripts/modal';
import { usePlanSelectorV3State } from '../../../../../hooks';

interface PlantTitleProps {
  planInfo: {
    img: string;
    imgTitle: string;
    title: string;
    description: string;
    learnMore: boolean;
  };
  isLastChild?: boolean;
}

export const PlanTitle: React.FC<PlantTitleProps> = ({
  planInfo,
  isLastChild
}) => {
  const { renderLearnMore, trackClickEvent } = usePlanSelectorV3State();
  const [openLearnMoreModal, setOpenLearnMoreModal] = useState(false);

  const onClick = () => {
    setOpenLearnMoreModal(true);
    trackClickEvent('paper-addon-learn-more', 'ii_plan_selector');
  };
  return (
    <Container isLastChild={isLastChild} data-testid="plan-title-container">
      <ItemsContainer isLastChild={isLastChild}>
        <img src={planInfo.img} alt={planInfo.imgTitle} />
        <TitleContainer>
          <Title>{planInfo.title}</Title>
          <Description>{planInfo.description}</Description>
          {planInfo.learnMore && (
            <LearnMore onClick={onClick} data-analyticsid="ii_plan_selector">
              Learn more
            </LearnMore>
          )}
        </TitleContainer>
      </ItemsContainer>
      <Modal
        maxWidth={920}
        show={openLearnMoreModal}
        closeButton
        onClose={() => setOpenLearnMoreModal(false)}
      >
        {renderLearnMore(() => setOpenLearnMoreModal(false))}
      </Modal>
    </Container>
  );
};
